<template>
  <div class="groups__export-button">
    <div class="groups__dropdown">
      <vs-button
        class="btnx vs-con-loading__container"
        type="flat"
        icon="download"
        @click="downloadStudents()"
        :disabled="downloading"
        ref="loadableButton"
        id="button-with-loading"
        >Export (with active students)</vs-button
      >
      <vs-dropdown vs-trigger-click>
        <vs-button class="btn-drop" type="flat" icon="expand_more"></vs-button>
        <vs-dropdown-menu class="groups__export__timesheet">
          <vs-dropdown-item @click="downloadStudents()">
            Export Active students
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      now: this.$moment(),
      downloading: false,
    }
  },
  computed: {
    ...mapState({
      currentYear: state => state.years.currentSchoolYear,
    }),
    months: function() {
      if (this.currentYear) {
        const start = this.$moment(this.currentYear.date_start_unix * 1000)
        return Array(12)
          .fill(null)
          .map((m, index) => {
            return start.clone().add('month', index)
          })
      }
      return []
    },
    prevMonth: function() {
      return this.now.clone().add('month', -1)
    },
  },
  methods: {
    downloadStudents: function() {
      const date = this.$moment()
      this.downloading = true
      this.$vs.loading({
        background: 'primary',
        color: '#fff',
        container: this.$refs.loadableButton.$el,
        scale: 0.45,
      })
      const schoolyear = this.currentYear.id
      console.log(schoolyear)
      this.$store
        .dispatch('groups/exportActiveStudentsAction', {
          schoolyear,
        })
        .then(result => {
          const url = 'data:application/vnd.ms-excel;base64,' + result.body
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `groups_students_${date.format('MMMM_YYYY')}.xls`
          )
          link.style.display = 'none'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          this.downloading = false
          this.$vs.loading.close(this.$refs.loadableButton.$el)
        })
        .catch(e => {
          this.$vs.notify({
            title: 'Error on download',
            text: e.message,
            color: 'danger',
            position: 'top-right',
          })
          this.downloading = false
          this.$vs.loading.close(this.$refs.loadableButton.$el)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.teachers__dropdown {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  .btnx {
    margin-left: 10px !important;
    border-radius: 5px 0px 0px 5px;
  }
  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}
.teachers__export__timesheet {
  min-width: 300px;
}
</style>
